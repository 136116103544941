import * as React from "react";

interface Props {
  onClick?: (e: React.FormEvent<HTMLButtonElement>) => void;
  className?: string;
  disabled?: boolean;
  type?: "submit" | "button" | "reset";
}

const Button: React.FC<Props> = ({
  onClick,
  className,
  children,
  disabled,
    type ,
}) => {
  return (
    <button
        type={type? type : 'button'}
      onClick={onClick}
      disabled={disabled}
      className={`btn text-light btn-info custom-button d-flex justify-content-center align-items-center ${className}`}
    >
      {children}
    </button>
  );
};
export default Button;
