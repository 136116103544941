import React from "react";
import { IconButton } from "../atomics";
import { cilPencil, cilTrash } from "@coreui/icons";
import { deleteTourCategory } from "../../services/TourCategoryService";
import HandleUIError from "../../utils/HandleUIError";
import confirmationDialog from "../toast-dialogs/ConfirmationDialog";

const AppTourCategoryList = ({ category, index, getCategoriesData, handleEdit }) => {
  const handleDelete = async () => {
    if (category?.id) {
      try {
        const res = await deleteTourCategory(category?.id);
        // setLoading(false)
        if (res?.status === 200) {
          await getCategoriesData();
          // handleClose()
        }
      } catch (e) {
        //         setLoading(false);
        HandleUIError(e);
      }
    }
  };

  function onPressDelete() {
    confirmationDialog({
      title: "Are you sure you want to delete this?",
      confirmAction: handleDelete,
    });
  }
  return (
    <>
      <div
        key={category?.id}
        className="d-flex py-3 mx-3 justify-content-between justify-content-sm-start px-4 user-information-container"
      >
        <div className="d-flex w-25 justify-content-between">
          <div className="message-title d-flex align-items-center w-100 ">
            <p className="ms-2 text-light">{index+1}</p>
          </div>
        </div>
        <div className="d-flex w-50 justify-content-between">
          <div className="message-title d-flex align-items-center w-100 ">
            <p className="ms-2 text-light">{category?.name}</p>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end pe-2 w-50">
          <IconButton
            icon={cilPencil}
            size="lg"
            className="me-2 edit-template-icon mt-1"
            onClick={() => handleEdit(category)}
          />
          <IconButton
            icon={cilTrash}
            onClick={() => {
              onPressDelete();
            }}
            className="m-0"
            size="lg"
          />
        </div>
      </div>
    </>
  );
};

export default AppTourCategoryList;
