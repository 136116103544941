export function validateEmail(email: string): boolean {
  const pattern = /^\w+([.\-+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  if (pattern.test(email)) {
    return true;
  } else {
    return false;
  }
}

export function validateLength(
  field: string | number,
  min: number,
  max?: number
): boolean {
  const value = field.toString().length;
  if (max) {
    if (value >= min && value <= max) {
      return true;
    } else {
      return false;
    }
  } else {
    if (value >= min) {
      return true;
    } else {
      return false;
    }
  }
}
