import {
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import * as React from "react";
import "./TextEditor.scss";
import CIcon from "@coreui/icons-react";
import { cilTextSize } from "@coreui/icons";

interface Props {
  toggleBlockType: (event: any) => void;
}

const blockTypeButtons = [
  {
    label: "h1",
    block: "header-one",
    tag: "h1",
  },
  {
    label: "h2",
    block: "header-two",
    tag: "h2",
  },
  {
    label: "h3",
    block: "header-three",
    tag: "h3",
  },
  {
    label: "h4",
    block: "header-four",
    tag: "h4",
  },
  {
    label: "Normal",
    block: "blockquote",
    tag: "blockquote",
  },
];

interface TagRenderProps {
  tag: string;
}

const TagRender: React.FC<TagRenderProps> = ({ tag, children }) => {
  switch (tag) {
    case "h1":
      return <h1>{children}</h1>;
    case "h2":
      return <h2>{children}</h2>;
    case "h3":
      return <h3>{children}</h3>;
    case "h4":
      return <h4>{children}</h4>;
    case "blockquote":
      return <p>{children}</p>;
    default:
      return <p>{children}</p>;
  }
};

const BlockStyleSelector: React.FC<Props> = ({ toggleBlockType }) => {
  return (
    <CDropdown className="block-style-selector m-0 me-2" direction="dropup">
      <CDropdownToggle
        color="dark"
        className=" d-flex align-items-center justify-content-center"
      >
        <CIcon icon={cilTextSize} size="lg" />
      </CDropdownToggle>
      <CDropdownMenu color="dark">
        {blockTypeButtons.map((button) => {
          return (
            <CDropdownItem
              onClick={toggleBlockType}
              key={button.label}
              data-block={button.block}
            >
              <TagRender tag={button.tag}>{button.label}</TagRender>
            </CDropdownItem>
          );
        })}
      </CDropdownMenu>
    </CDropdown>
  );
};

export default BlockStyleSelector;
