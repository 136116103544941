import * as React from "react";
import "./NewTemplate.scss";
//components
import { Modal, Button, Input, Spinner } from "../../components/atomics";
import { TextEditor } from ".";

//services
import { updateMessagetemplate } from "../../services/messagesService";
import HandleUIError from "../../utils/HandleUIError";

interface Props {
  visible: boolean;
  closeModal: () => void;
  text?: string;
  id?: number;
  isText?: boolean;
  isAudio?: boolean;
  isEmail?: boolean;
  name?: string | null;
  title?: string | null;
  editMode?: boolean;
  setEditMode: (e: boolean) => void;
  refreshTemplates: () => Promise<void>;
  filePath: string | null;
  isActive: (id: any, status: any) => void;
}

const TemplateContent: React.FC<Props> = ({
  id,
  visible,
  closeModal,
  text,
  name,
  editMode,
  setEditMode,
  refreshTemplates,
  isText,
  isAudio,
  isEmail,
  filePath,
  title,
  isActive,
}) => {
  const [newText, setNewText] = React.useState(text ? text : "");
  const [newName, setNewName] = React.useState(name ? name : "");
  const [newTitle, setNewTitle] = React.useState(title ? title : "");
  const [loading, setLoading] = React.useState(false);

  function onChangeNewText(t: string) {
    if (t !== text) {
      setEditMode(true);
    }
    setNewText(t);
  }

  function onChangeNewName(e: any) {
    setEditMode(true);
    setNewName(e.target.value);
  }

  function onChangeNewTitle(e: any) {
    setEditMode(true);
    setNewTitle(e.target.value);
  }

  function onCloseModal() {
    setEditMode(false);
    setNewName("");
    setNewText("");
    setEditMode && setEditMode(false);
    closeModal();
  }

  async function unPressSave() {
    setLoading(true);
    if (id) {
      try {
        await updateMessagetemplate(id, newText, newName, newTitle);
        await refreshTemplates();
        closeModal();
        setLoading(false);
      } catch (e) {
        setLoading(false);
        HandleUIError(e);
      }
    }
  }

  return (
    <Modal visible={visible} onPressOut={onCloseModal}>
      <div className="new-template-container">
        <div className="new-template-header d-flex justify-content-between align-items-center">
          <p className="text-light fs-5">Message template</p>
          {editMode && (
            <Button onClick={unPressSave} className="px-4" disabled={loading}>
              {loading ? <Spinner /> : "Save"}
            </Button>
          )}
        </div>
        <div className="search-phone d-flex align-items-center pe-3 ps-3">
          <p className="text-light fs-5 template-name-width">Template name:</p>
          <Input
            defaultValue={name ? name : ""}
            className="mt-2 w-50 mb-2 ms-3 text-light w-100"
            onChange={onChangeNewName}
          />
        </div>
        {!isAudio && (
          <div>
            <div className="d-flex align-items-center w-50 ms-3 w-100 pe-4">
              <p className="text-light fs-5 mb-2">
                {isText ? "Title:" : "Subject:"}
              </p>
              <Input
                defaultValue={title ? title : ""}
                className="w-100 mb-3 ms-3 text-light m-0 me-2"
                onChange={onChangeNewTitle}
              />
            </div>
            <TextEditor
              isEmail={isEmail}
              className=""
              defaultValue={text}
              onChangeText={onChangeNewText}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default TemplateContent;
