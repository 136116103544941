import * as React from "react";
import "../App.scss";
import { NavBar, SideBar } from ".";
import { BrowserRouter } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import { Login } from "../auth";
import LoadRoutes from "../routes/LoadRoutes";
import ToasterConfig from "../utils/ToasterConfig";
import AppRouting from "../routes/AppRouting";

const DefaultLayout: React.FC = () => {
  const [sideBarVisible, setSideBarVisible] = React.useState(true);
  function toggleSideBarVisible() {
    setSideBarVisible(!sideBarVisible);
  }

  const token = useAppSelector((state) => state.UserSlice.tokens.access);
  const admin = useAppSelector((state) => state.UserSlice.user.isAdmin);
  const isOrganizationManager = useAppSelector(
    (state) => state.UserSlice.user.isOrganizationManager
  );
  return (
    <BrowserRouter>
      <ToasterConfig />
      {token && admin && isOrganizationManager ? (
        <>
          <div className="default-layout">
            <SideBar visible={sideBarVisible} />
            <div className="main-layout">
              <NavBar toggleSideBarVisible={toggleSideBarVisible} />
              <LoadRoutes />
            </div>
          </div>
        </>
      ) : (
        <>
          {/* <Login /> */}
          <AppRouting />
        </>
      )}
    </BrowserRouter>
  );
};

export default DefaultLayout;
