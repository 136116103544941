import * as React from "react";
import "./NewOrganization.scss";

//components
import { Modal, IconButton, Input, Button } from "../../components/atomics";
import { cilX } from "@coreui/icons";
import { CFormCheck, CSpinner } from "@coreui/react";

//services
import {
  createOrganization,
  updateOrganization,
} from "../../services/OrganizationsServices";
import HandleUIError from "../../utils/HandleUIError";
import toast from "react-hot-toast";
import { OrgSelected } from "../../utils/types";

interface Props {
  visible: boolean;
  closeModal: () => void;
  refreshOrganizations: () => void;
  editMode?: boolean;
  organizationId?: number;
  organizationData?: any;
  closeView: () => void;
}

const NewOrganization: React.FC<Props> = ({
  visible,
  closeModal,
  refreshOrganizations,
  editMode,
  organizationId,
  organizationData,
  closeView,
}) => {
  const [name, setName] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("");
  const [stateCode, setStateCode] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [standard, setStandard] = React.useState(true);
  const [custom, setCustom] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [importTemplates, setImportTemplates] = React.useState<any>(false);
  const [pre_store_message, setPre_store_message] = React.useState(false);

  function onChangeName(e: any) {
    setName(e.target.value);
  }

  function onChangeAddress(e: any) {
    setAddress(e.target.value);
  }

  function onChangeCountryCode(e: any) {
    setCountryCode(e.target.value);
  }

  function onChangeStateCode(e: any) {
    setStateCode(e.target.value);
  }

  function onChangePostalCode(e: any) {
    setPostalCode(e.target.value);
  }
  function onChangeCustom(e: any) {
    setCustom(e.target.checked);
  }
  // function onChangeStandard(e: any) {
  //   setStandard(e.target.checked);
  // }

  function onChangeImportTemplates(e: any) {
    setImportTemplates(e.target.checked);
    setPre_store_message(e.target.checked);
    setStandard(true)
  }

  async function onPressSave() {

    // return console.log(custom, standard)
    console.log('add===')
    setIsLoading(true);
    try {
      if (name === "") {
        toast.error("Name is required");
        return;
      }
      await createOrganization(
        {
          name,
          address,
          countryCode,
          stateCode,
          postalCode,
          importTemplates,
          standard,
          custom,
          pre_store_message
        },


      );
      toast.success("Organization created successfully");
      setIsLoading(false);
      await refreshOrganizations();
      closeModal();
    } catch (e) {
      setIsLoading(false);
      HandleUIError(e);
    }
  }

  async function onPressSaveEdit() {
    console.log('save===')
    setIsLoading(true);
    try {
      if (organizationData) {
        const body = {
          name: name === "" ? organizationData.name : name,
          address: address === "" ? organizationData.address : address,
          countryCode:
            countryCode === "" ? organizationData.countryCode : countryCode,
          stateCode: stateCode === "" ? organizationData.stateCode : stateCode,
          postalCode:
            postalCode === "" ? organizationData.postalCode : postalCode,
          importTemplates: importTemplates == true ? organizationData.importTemplates : importTemplates,
          standard: standard === true ? organizationData.standard : standard,
          custom: custom === true ? organizationData.custom : custom,
          pre_store_message: pre_store_message === true ? organizationData.pre_store_message : pre_store_message

        };


        await updateOrganization(organizationData.id, body);
        toast.success("Organization updated successfully");
      }
      setIsLoading(false);
      closeModal();
      refreshOrganizations();
      closeView();
    } catch (e) {
      setIsLoading(false);
      HandleUIError(e);
    }
  }

  return (
    <Modal visible={visible} onPressOut={closeModal}>
      <div className="new-organization-container">
        <div className="new-organization-header">
          <p className="text-light py-2 ps-4 fs-5">
            {editMode ? `Organization ${organizationId}` : "New Organization"}
          </p>
          <IconButton icon={cilX} className="me-3" onClick={closeModal} />
        </div>
        <div className="mx-4 mt-2">
          <div className="d-sm-flex">
            <div className="w-100 me-sm-3">
              <p className="text-light">Name</p>
              <Input
                defaultValue={organizationData?.name}
                className="w-100"
                onChange={onChangeName}
              />
            </div>
            <div className="w-100">
              <p className="text-light">Address</p>
              <Input
                defaultValue={organizationData?.address}
                className="w-100"
                onChange={onChangeAddress}
              />
            </div>
          </div>
          <div className="d-sm-flex">
            <div className="w-100 me-sm-3">
              <p className="text-light">Country</p>
              <Input
                defaultValue={organizationData?.countryCode}
                className="w-100"
                onChange={onChangeCountryCode}
              />
            </div>
            <div className="w-100">
              <p className="text-light">State</p>
              <Input
                defaultValue={organizationData?.stateCode}
                className="w-100"
                onChange={onChangeStateCode}
              />
            </div>
          </div>
          <div className="d-sm-flex m-0 me-3">
            <div className="w-50 me-sm-3">
              <p className="text-light">ZIP Code</p>
              <Input
                defaultValue={organizationData?.postalCode}
                className="w-100"
                onChange={onChangePostalCode}
              />
            </div>
            {!editMode ? (
              <div className="mt-4 d-flex">
                <p className="text-light m-0 me-2">
                  Import pre-stored messages:
                </p>
                {/* {console.log('organizationData?.pre_store_message', organizationData?.pre_store_message)} */}
                <CFormCheck
                  defaultChecked={organizationData?.pre_store_message}
                  // checked={organizationData?.pre_store_message}
                  onChange={onChangeImportTemplates}
                />
              </div>
            ) : <div className="mt-4 d-flex">
              <p className="text-light m-0 me-2">
                Import pre-stored messages:
              </p>
              <CFormCheck
                // checked={organizationData?.pre_store_message}
                defaultChecked={organizationData?.pre_store_message}
                onChange={onChangeImportTemplates}
              />
            </div>}


          </div>
          {importTemplates || organizationData?.pre_store_message ?
            <>
              <div className="mt-4 d-flex">
                <div className="w-50 d-flex">

                  <p className=" text-light m-0 me-2">
                    Custom :
                  </p>
                  <CFormCheck
                    defaultChecked={organizationData?.custom}
                    // checked={organizationData?.custom}
                    // value={custom}
                    onChange={onChangeCustom}
                  />
                </div>
                {/* </div> */}

                {/* <div className="d-none w-50 d-flex ms-3">

                  <p className="text-light m-0 me-2">
                    Standard:
                  </p>
                  <CFormCheck
                    defaultChecked={organizationData?.standard}
                    onChange={onChangeStandard}
                  />
                </div> */}
              </div>
            </>
            : ""
          }
          <Button
            onClick={editMode ? onPressSaveEdit : onPressSave}
            className="w-100 mt-3 mb-3"
          >
            {isLoading ? <CSpinner /> : "Save"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default NewOrganization;
