import * as React from "react";
import "./NavBar.scss";
import { CNavbarBrand, CNavbar, CContainer, CButton } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";
import { UserAvatar } from "../components/atomics";
import { useAppSelector } from "../redux/hooks";
import { useLocation } from "react-router-dom";

interface Props {
  toggleSideBarVisible: () => void;
}

const NavBar: React.FC<Props> = ({ toggleSideBarVisible }) => {
  const [title, setTitle] = React.useState("");
  const [visibleAlert, setVisibleAlert] = React.useState(false);
  const userSlice = useAppSelector((state) => state.UserSlice);
  const location = useLocation();

  const userEmail = userSlice.user.email;

  const iconLetter = userEmail.substring(0, 2);

  React.useEffect(
    function () {
      switch (location.pathname) {
        case "/":
          setTitle("Organizations");
          break;
        default:
          setTitle("");
          break;
      }
    },
    [location.pathname]
  );

  return (
    <>
      <CNavbar
        expand="lg"
        colorScheme="dark"
        className="custom-header bg-dark1 navBarShadow"
      >
        <CContainer fluid>
          <div className="d-flex align-items-center">
            <CIcon
              icon={cilMenu}
              size="xxl"
              onClick={toggleSideBarVisible}
              className="me-3 ms-2 icon-white sidebar-button-nav"
            />
            <CNavbarBrand href="#" className="">
              {title}
            </CNavbarBrand>
          </div>
          <div className="d-flex">
            <UserAvatar iconLetter={iconLetter} size={2} isLoggedUser />
          </div>
        </CContainer>
      </CNavbar>
    </>
  );
};

export default NavBar;
