import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import ForgetPassword from '../auth/forgetPassword'
import Verification from '../auth/verification'
import OtpScreen from '../auth/otpScreen'
import { Login } from '../auth'

const AppRouting = () => {
    return (
        <BrowserRouter>

            <Switch>
                <Route exact path="/">
                    <Login />
                </Route>
                <Route path="/new-password">
                    <ForgetPassword />
                </Route>
                <Route path="/verify">
                    <Verification />
                </Route>
                <Route path="/reset-password">
                    <OtpScreen />
                </Route>
            </Switch>

        </BrowserRouter>
    )
}

export default AppRouting