import axiosConfig from "./axiosConfig";
import ApiError from "../utils/ApiError";


export async function getDashboardInfo() {
    try {
        const response = await axiosConfig.get(`/admin/dashboard-info`);
        return response?.data
    } catch (e) {
        throw new ApiError(e);
    }
}