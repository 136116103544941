// import { current } from "immer";
import * as React from "react";
import "./Pagination.scss";

interface Props {
  numberOfPages: number;
  currentPage: number;
  setCurrentPage: any;
}

const Pagination: React.FC<Props> = ({
  numberOfPages,
  setCurrentPage,
  currentPage,
}) => {
  const windowWidth = window.innerWidth;
  const movilInnerWidth = 576; //at this value the screen adopts the mobile design
  const [numberOfPagesArray, setNumberOfPagesArray] =
    React.useState<number[]>();

  React.useEffect(
    function () {
      let auxArray = [];
      for (var i = 1; i < numberOfPages + 1; i++) {
        auxArray.push(i);
      }
      setNumberOfPagesArray(auxArray);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [numberOfPages]
  );

  function setNextPage() {
    if (numberOfPagesArray) {
      if (currentPage < numberOfPagesArray?.length) {
        setCurrentPage(currentPage + 1);
      }
    }
  }

  function setPrevPage() {
    if (numberOfPagesArray) {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    }
  }

  function setNumberOfIndexToShow() {
    //If the screen is on mobile, it only shows the first 3 numbers, and then ellipsis, on the other hand, the desktop screen will show up to 5
    if (windowWidth < movilInnerWidth) {
      return 2;
    } else {
      if (numberOfPages > 10) {
        return 10;
      } else {
        return numberOfPages + 1;
      }
    }
  }

  interface RenderItemProps {
    page: number;
    selected: boolean;
  }

  const RenderItem: React.FC<RenderItemProps> = ({ page, selected }) => {
    return !selected ? (
      <div className="pagination-item" onClick={() => setCurrentPage(page)}>
        <p className="pagination-text noselect-text">{page}</p>
      </div>
    ) : (
      <div className="pagination-selected">
        <p className="pagination-text noselect-text">{page}</p>
      </div>
    );
  };

  interface PropsRenderEllipsis {
    currentPage: number;
    setCurrentPage: any;
  }

  const RenderEllipsis: React.FC<PropsRenderEllipsis> = ({
    currentPage,
    setCurrentPage,
  }) => {
    return (
      <>
        <div className="pagination-item">
          <p className="pagination-text noselect-text">...</p>
        </div>
        {currentPage > 10 && (
          <>
            <div
              className="pagination-item"
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              <p className="pagination-text">{currentPage - 1}</p>
            </div>
            <div className="pagination-selected">
              <p className="pagination-text">{currentPage}</p>
            </div>
            <div
              className="pagination-item"
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              <p className="pagination-text">{currentPage + 1}</p>
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <div className="d-flex">
        <div className="pagination-prev" onClick={setPrevPage}>
          <p className="pagination-text noselect-text">Prev</p>
        </div>
        {numberOfPagesArray
          ? numberOfPagesArray.map((item, index) => {
              return (
                <div key={index}>
                  {item <= setNumberOfIndexToShow() ? (
                    <RenderItem
                      key={index}
                      page={item}
                      selected={item === currentPage}
                    />
                  ) : null}
                </div>
              );
            })
          : null}
        {numberOfPagesArray &&
        numberOfPagesArray.length > setNumberOfIndexToShow() ? (
          <RenderEllipsis
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : null}
        {numberOfPagesArray !== undefined &&
          currentPage < numberOfPagesArray?.length && (
            <div className="pagination-next" onClick={setNextPage}>
              <p className="pagination-text noselect-text">Next</p>
            </div>
          )}
      </div>
    </>
  );
};

export default Pagination;
