import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';

const data = [
    { name: 'Total Organization', uv: 4, pv: 2400, amt: 2400 },
    { name: 'Active Organization', uv: 3, pv: 2400, amt: 2400 },
    { name: 'Inactive Organization', uv: 1, pv: 2400, amt: 2400 },
];

const RenderLineChart = () => {
    return (
        <LineChart width={1000}  height={400} data={data}>
            <Line type="monotone" dataKey="uv" stroke="blue" />
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="name" />
            <YAxis />
        </LineChart>
    );
};

export default RenderLineChart;
