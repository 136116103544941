import axiosConfig from "./axiosConfig";
import ApiError from "../utils/ApiError";


export async function getTourCategories(offSetLimit = 0, title = "", order = "DESC") {
    try {
        const response = await axiosConfig.get(`admin/tour-categories?limit=10&title=${title}&page=${offSetLimit}&order=${order}`);
        return response?.data
    } catch (e) {
        throw new ApiError(e);
    }
}

export async function createTourCategory(data) {
    try {
        const response = await axiosConfig.post(`admin/tour-categories`, data);
        return response?.data
    } catch (e) {
        throw new ApiError(e);
    }
}

export async function updateTourCategory(data, id) {
    try {
        const response = await axiosConfig.put(`admin/tour-categories/${id}`, data);
        return response?.data
    } catch (e) {
        throw new ApiError(e);
    }
}

export async function deleteTourCategory(id) {
    try {
        const response = await axiosConfig.delete(`admin/tour-categories/${id}`);
        return response?.data
    } catch (e) {
        throw new ApiError(e);
    }
}


export async function getTourSection(offSetLimit = 0, title = "", order = "DESC") {
    try {
        const response = await axiosConfig.get(`admin/app-tour?limit=10&title=${title}&page=${offSetLimit}&order=${order}`);
        return response?.data
    } catch (e) {
        throw new ApiError(e);
    }
}

export async function createTourSection(data) {
    try {
        const response = await axiosConfig.post(`admin/app-tour`, data);
        return response?.data
    } catch (e) {
        throw new ApiError(e);
    }
}

export async function updateTourSection(data, id) {
    try {
        const response = await axiosConfig.put(`admin/app-tour/${id}`, data);
        return response?.data
    } catch (e) {
        throw new ApiError(e);
    }
}

export async function deleteTourSection(id) {
    try {
        const response = await axiosConfig.delete(`admin/app-tour/${id}`);
        return response?.data
    } catch (e) {
        throw new ApiError(e);
    }
}