import * as React from "react";
import "./TextEditor.scss";
import { EditorState, Editor, ContentState, RichUtils } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import "draft-js/dist/Draft.css";
import { CButton } from "@coreui/react";

//components
import { BlockStyleSelector } from ".";

//services
import {
  htmlToDeviceFormat,
  convertToStateFromHTML,
} from "../../utils/FormatConverters";

interface Props {
  className?: string;
  onChangeText?: (e: any) => void;
  defaultValue?: string;
  isEmail?: boolean;
}

const TextEditor: React.FC<Props> = ({
  className,
  onChangeText,
  defaultValue,
  isEmail,
}) => {
  const MAX_LENGTH = isEmail ? 300 : 144;
  const [editorState, setEditorState] = React.useState(
    !defaultValue
      ? EditorState.createEmpty()
      : EditorState.createWithContent(convertToStateFromHTML(defaultValue))
  );
  const [plainTextLenght, setPlainTextLenght] = React.useState(0);

  React.useEffect(
    function () {
      //convert de editorState to html string
      const html = stateToHTML(
        new ContentState(editorState.getCurrentContent())
      );
      if (onChangeText) {
        if (isEmail) {
          onChangeText(html);
        } else {
          //convert html string to device format
          onChangeText(htmlToDeviceFormat(html));
        }
      }
      //get text lenght
      setPlainTextLenght(editorState.getCurrentContent().getPlainText().length);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editorState]
  );

  // function _onHighlightClick() {
  //   setEditorState(RichUtils.toggleBlockType(editorState, "header-three"));
  // }

  //limit the max length of the text
  function _handleBeforeInput(): any {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    if (currentContentLength > MAX_LENGTH - 1) {
      return "handled";
    }
  }
  const _handlePastedText = (pastedText: any): any => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    if (currentContentLength + pastedText.length > MAX_LENGTH) {
      return "handled";
    }
  };
  const _handleChange = (editorState: any) => {
    setEditorState(editorState);
  };

  function toggleInlineStyle(event: any) {
    event.preventDefault();
    const style = event.currentTarget.getAttribute("data-style");
    setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  }

  function toggleBlockType(event: any) {
    event.preventDefault();
    const block = event.currentTarget.getAttribute("data-block");
    setEditorState(RichUtils.toggleBlockType(editorState, block));
  }

  const handleReturn = (): "handled" | "not-handled" => {
    const currentContentLength = editorState.getCurrentContent().getPlainText("").length;
    if (currentContentLength >= MAX_LENGTH) {
      return "handled"; // Blocks the Enter key when the text limit is reached
    }
    return "not-handled"; // Allows Enter key otherwise
  };

  return (
    <div className={`text-editor-container ${className}`}>
      <div className="toolbar-style-text-editor">
        {/* Style buttons */}
        {isEmail && (
          <div className="d-flex mb-1">
            <CButton
              color="dark"
              className="style-button m-0 me-2"
              onClick={toggleInlineStyle}
              data-style="BOLD"
            >
              <strong>B</strong>
            </CButton>
            <CButton
              color="dark"
              className="style-button m-0 me-2"
              onClick={toggleInlineStyle}
              data-style="ITALIC"
            >
              <em>IT</em>
            </CButton>
            <CButton
              color="dark"
              className="style-button m-0 me-2"
              onClick={toggleInlineStyle}
              data-style="UNDERLINE"
            >
              <u>U</u>
            </CButton>
            <BlockStyleSelector toggleBlockType={toggleBlockType} />
          </div>
        )}
        <div className="" />
        <p className="py-1">{`${plainTextLenght}/${isEmail ? "300" : "144"}`}</p>
      </div>
      <Editor
        editorState={editorState}
        onChange={_handleChange}
        handleBeforeInput={_handleBeforeInput}
        handlePastedText={_handlePastedText}
        handleReturn={handleReturn}
      />
    </div>
  );
};

export default TextEditor;
