import * as React from "react";
import HandleUIError from "../../utils/HandleUIError";
import toast from "react-hot-toast";

//components
import {
  Modal,
  Input,
  Button,
  Spinner,
  IconButton,
} from "../atomics";
import { cilX } from "@coreui/icons";
import { User, UserData } from '../../services/types';
import { updatedAdmin } from "../../services/AdminServices";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

interface Props {
  visible: boolean;
  closeModal: () => void;
  refreshAdmins: () => void;
  user: User
  organizationId: number;
}

const EditAdmin: React.FC<Props> = ({ visible, closeModal, refreshAdmins, user, organizationId }) => {
  const [firstName, setFirstName] = React.useState<string>(user.firstName);
  const [lastName, setLastName] = React.useState<string>(user.lastName);
  const [userEmail, setUserEmail] = React.useState<string>(user.email);
  const [phone, setPhone] = React.useState<string>(user.phone);
  const [password, setPassword] = React.useState<string | null>(null);
  const [addUserLoading, setAddUserLoading] = React.useState<boolean>(false);


  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  const handleInputFirstName = (e: any) => {
    setFirstName(e.target.value);
  };

  const handleInputLastName = (e: any) => {
    setLastName(e.target.value);
  };

  const handleInputMail = (e: any) => {
    setUserEmail(e.target.value);
  };

  const handleInputPhone = (e: any) => {
    setPhone(e.target.value);
  };

  const handleInputPassword = (e: any) => {
    setPassword(e.target.value);
  };

  async function updateUser(firstName: string, lastName: string, email: string, phone: string, password?: string | null): Promise<void> {

    if (!pattern.test(email)) {
      toast.error("Please enter valid email address")
      return

    } else {
      try {
        setAddUserLoading(true);

        if (email === "" || email === null) {
          toast.error("Email cannot be empty")
          setAddUserLoading(false);
        } else {
          let userData: UserData | null;
          if (password !== null) {
            userData = {
              "firstName": firstName,
              "lastName": lastName,
              "email": email,
              "phone": phone,
              "password": password
            }
          } else {
            userData = {
              "firstName": firstName,
              "lastName": lastName,
              "email": email,
              "phone": phone
            }
          }
          await updatedAdmin(organizationId, user.id, userData);
          await refreshAdmins();
          toast.success("Admin updated succesfully");
          setAddUserLoading(false);
          closeModal();
        }
      } catch (e) {
        setAddUserLoading(false);
        toast.error("Error updating admin. Try again.")
        HandleUIError(e);
      }
    }

  }

  return (
    <Modal visible={visible} onPressOut={closeModal}>
      <div className="edit-user-container">
        <div className="edit-user-header">
          <p className="text-light py-2 ps-4 fs-5">Edit admin</p>
          <IconButton icon={cilX} className="me-3" onClick={closeModal} />
        </div>

        <div className="users-container">
          <p className="text-light fs-5 ms-3 pt-3">
            First Name
          </p>
          <Input
            className="mt-2 mb-3 ms-3 search-phone-input"
            onChange={handleInputFirstName}
            defaultValue={firstName}
            autoFocus
          />
          <p className="text-light fs-5 ms-3 pt-3">
            Last Name
          </p>
          <Input
            className="mt-2 mb-3 ms-3 search-phone-input"
            onChange={handleInputLastName}
            defaultValue={lastName}
          />
          <p className="text-light fs-5 ms-3 pt-3">
            Email
          </p>
          <Input
            className="mt-2 mb-3 ms-3 search-phone-input"
            onChange={handleInputMail}
            defaultValue={userEmail}
          />
          <p className="text-light fs-5 ms-3 pt-3">
            Phone
          </p>
          <PhoneInput
            value={phone}
            className="ms-3 me-4 mt-2 bg-transparent "
            onChange={(e: any) => { setPhone(e) }}
          />
          <p className="text-light fs-5 ms-3 pt-3">
            Password
          </p>
          <Input
            type="password"
            className="mt-2 ms-3 search-phone-input"
            onChange={handleInputPassword}
          />
          <p className="text-warning ms-3 mb-3">*** Only modify if you want to change the password ***</p>
          <div className="d-flex justify-content-center">
            <Button
              disabled={addUserLoading}
              className="mt-7 button-add px-4 mb-3"
              onClick={() => {
                updateUser(firstName, lastName, userEmail, phone, password)
              }}
            >
              {!addUserLoading ? "Save" : <Spinner />}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditAdmin;