import * as React from "react";
import "./Atomics.scss";

interface Props {
  className?: string;
}

const PageContainer: React.FC<Props> = ({ children, className }) => {
  return (
    <div className={`page-container rounded ${className}`}>{children}</div>
  );
};

export default PageContainer;
