import * as React from "react";

import CIcon from "@coreui/icons-react";
import {
  cilVolumeHigh,
  cilTrash,
  cilPencil,
  cilText,
  cilMediaPlay,
  cilMediaStop,
  cilEnvelopeClosed,
} from "@coreui/icons";
import BenzAMRRecorder from "benz-amr-recorder";

//component
import { IconButton } from "../../components/atomics";
import confirmationDialog from "../toats-dialogs/ConfirmationDialog";
import { TemplateContent } from ".";

//services
import {
  deleteMessageTemplate,
  getUdioUrlByFilePath,
  updateMessageTemplateStatus,
} from "../../services/messagesService";
import HandleUIError from "../../utils/HandleUIError";
import checklist from "../../assets/checklist.png";
import add from "../../assets/add.png";

interface Props {
  name: string | null;
  id?: number;
  refreshTemplates: () => Promise<void>;
  isText?: boolean;
  isEmail?: boolean;
  isAudio?: boolean;
  text: string;
  title: string;
  category?: string;
  filePath: string | null;
  isActive?: boolean;
  status?: boolean;
}

const PreStoredMessagesItem: React.FC<Props> = ({
  name,
  id,
  refreshTemplates,
  isText,
  isAudio,
  isEmail,
  text,
  category,
  filePath,
  title,
  status,
}) => {
  const [isLoadingDelete, setIsLoadingDelete] = React.useState(false);
  const [showContent, setShowContent] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [loadingPlayAudio, setLoadingPlayAudio] = React.useState(false);
  const [checkStatus, setCheckStatus] = React.useState(Boolean);
  const isCustom = category === "custom";
  const [currentAudio, setCurrentAudio] =
    React.useState<any>(null);

  const [loading, setLoading] = React.useState(false);

  function closeModalContent() {
    setShowContent(false);
  }
  function openEditModal() {
    setShowContent(true);
    setEditMode(true);
  }

  async function deleteTemplate() {
    if (id) {
      setIsLoadingDelete(true);
      try {
        await deleteMessageTemplate(id);
        await refreshTemplates();
        setIsLoadingDelete(false);
      } catch (e) {
        setIsLoadingDelete(false);
        HandleUIError(e);
      }
    }
  }


  function onPressDelete() {
    confirmationDialog({
      title: "Are you sure you want to delete this template?",
      confirmAction: deleteTemplate,
    });
  }

  async function onPressPlay() {
    setLoadingPlayAudio(true);
    try {
      if (filePath) {
        let audio: any = new Audio(filePath)
        // const audioData = await getUdioUrlByFilePath(filePath);
        // const amr = new BenzAMRRecorder();
        // await amr.initWithUrl(audioData.audioUrl);
        setLoadingPlayAudio(false);
        audio.play()
        setCurrentAudio(audio);

        // amr.play();

      }
    } catch (e) {
      setLoadingPlayAudio(false);
      HandleUIError(e);
    }
  }

  React.useEffect(() => {
    const handleAudioEnded = () => {
      setCurrentAudio(null);
    };

    // Attach the ended event listener
    currentAudio && currentAudio.addEventListener('ended', handleAudioEnded);

    // Cleanup the event listener on component unmount
    return () => {
      currentAudio && currentAudio.removeEventListener('ended', handleAudioEnded);
    };

  }, [currentAudio]);

  function onPressStop() {
    currentAudio?.pause();
    setCurrentAudio(null);
  }
  //runs when the audio finishes playing
  // currentAudio?.onEnded(() => setCurrentAudio(null));

  const handleStatus = async (id: any, status: any) => {
    // setCheckStatus(status)
    if (status === false) {
      setLoading(true);
      if (id) {
        try {
          await updateMessageTemplateStatus(id, true);
          await refreshTemplates();
          setLoading(false);
        } catch (e) {
          setLoading(false);
          HandleUIError(e);
        }
      }
    } else if (status === true) {
      try {
        await updateMessageTemplateStatus(id, false);
        await refreshTemplates();
        setLoading(false);
      } catch (e) {
        setLoading(false);
        HandleUIError(e);
      }
    }
  }


  return (
    <>
      <div className="d-flex my-3 w-100 justify-content-between justify-content-sm-start px-4">
        <div className="d-flex w-75 justify-content-between">
          <div className="message-title d-flex align-items-center w-100 ">
            {status == false ?

              <>
                <img src={add} width={25} height={25} alt="" />
              </>
              :
              <>
                <img src={checklist} width={25} height={25} alt="" />
              </>
            }
            <p className="ms-2 text-light">{name}</p>
          </div>
          <div className="">
            {isText && (
              <CIcon icon={cilText} size="lg" className="ms-1 messages-icon" />
            )}
            {isAudio && (
              <CIcon
                icon={cilVolumeHigh}
                size="lg"
                className="ms-1 messages-icon"
              />
            )}
            {isEmail && (
              <CIcon
                icon={cilEnvelopeClosed}
                size="lg"
                className="ms-1 messages-icon"
              />
            )}
          </div>

        </div>
        <div className="d-flex align-items-center justify-content-end pe-2 w-50 statusSwitch">
          <div className="form-check form-switch">
            <input className="form-check-input"
              type="checkbox" role="switch"
              checked={status === true}
              onChange={() => handleStatus(id, status)}
              id="flexSwitchCheckDefault" />
          </div>

        </div>
        <div className="d-flex align-items-center justify-content-end pe-2 w-50">
          {isAudio && (
            <>
              {!currentAudio ? (
                <IconButton
                  icon={cilMediaPlay}
                  size="lg"
                  className="me-2 edit-template-icon mt-1"
                  onClick={onPressPlay}
                  isLoading={loadingPlayAudio}
                />
              ) : (
                <IconButton
                  icon={cilMediaStop}
                  size="lg"
                  className="me-2 edit-template-icon mt-1"
                  onClick={onPressStop}
                />
              )}
            </>
          )}
          <IconButton
            icon={cilPencil}
            size="lg"
            className="me-2 edit-template-icon mt-1"
            onClick={openEditModal}
          />
          {isCustom && (
            <IconButton
              icon={cilTrash}
              onClick={onPressDelete}
              isLoading={isLoadingDelete}
              className="m-0"
              size="lg"
            />
          )}
        </div>
      </div>
      <TemplateContent
        visible={showContent}
        closeModal={closeModalContent}
        text={text}
        id={id}
        name={name}
        editMode={editMode}
        setEditMode={setEditMode}
        refreshTemplates={refreshTemplates}
        isText={isText}
        isAudio={isAudio}
        isEmail={isEmail}
        filePath={filePath}
        title={title}
        isActive={handleStatus}
      />
    </>
  );
};

export default PreStoredMessagesItem;
