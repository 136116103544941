
import * as React from "react";

import { cilPencil, cilTrash } from "@coreui/icons";
import { CAvatar } from "@coreui/react";
import toast from "react-hot-toast";
import { User } from "../../services/types";
import { IconButton } from "../atomics";
import EditAdmin from "./EditAdmin";
import { deleteAdmin, updateAdminStatus } from "../../services/AdminServices";
import HandleUIError from "../../utils/HandleUIError";
import confirmationDialog from "../toast-dialogs/ConfirmationDialog";

interface Props {
  user: User
  refreshAdmins: () => void;
  organizationId: number;
  setPreviousAction: (val: boolean) => void;
  previousAction: boolean;
}

const AdminsRow: React.FC<Props> = ({ user, refreshAdmins, organizationId, setPreviousAction, previousAction }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [visibleEdit, setVisibleEdit] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);


  function handleVisibleEdit() {
    setVisibleEdit(true)
  }

  function closeModal() {
    setVisibleEdit(false)
  }

  async function removeUser(organizationId: number, userId: number): Promise<void> {
    try {
      await toast.promise(deleteAdmin(organizationId, userId), {
        loading: "Removing user admin...",
        success: "Admin removed successfully",
        error: "Error removing admin from organization",
      });
      setIsLoading(false);
      setPreviousAction(false);
      await refreshAdmins();
    } catch (e) {
      HandleUIError(e);
    }
  }

  function onPressRemoveUser(organizationId: number, userId: number) {
    setPreviousAction(true);
    setIsLoading(true);
    const confirmationDialogOptions = {
      title:
        "Are you sure you want to remove this user admin?",
      confirmAction: () => removeUser(organizationId, userId),
      cancelAction: () => {
        setIsLoading(false)
        setPreviousAction(false)
      },
    };
    confirmationDialog(confirmationDialogOptions);
    setTimeout(() => {
      setIsLoading(false);
      setPreviousAction(false);
    }, 4000)
  }


  const handleStatus = async (id: any, status: any) => {
    // setCheckStatus(status)
    if (status === false) {
      setLoading(true);
      if (id) {
        try {
          await updateAdminStatus(id, true);
          await refreshAdmins();
          setLoading(false);
        } catch (e) {
          setLoading(false);
          HandleUIError(e);
        }
      }
    } else if (status === true) {
      try {
        await updateAdminStatus(id, false);
        await refreshAdmins();
        setLoading(false);
      } catch (e) {
        setLoading(false);
        HandleUIError(e);
      }
    }
  }

  return (
    <>
      <div className="d-flex py-3 user-information-container justify-content-around">
        <div className="section-width-users-1 d-flex justify-content-center">
          {user.avatarFile === null ? (
            <CAvatar
              color="primary"
              textColor="white"
              size="lg"
            >
              {user.firstName ? user.firstName[0].toUpperCase() : "-"}
              {user.lastName ? user.lastName[0].toUpperCase() : "-"}
            </CAvatar>
          ) : (
            <CAvatar src={user.avatarFile} size="lg" />
          )}
        </div>
        <div className="d-flex section-width-users-2 align-items-center">
          <p className="text-light word-break">
            {user.firstName ? user.firstName + " " : "-"}
            {user.lastName ? user.lastName : "-"}
          </p>
        </div>
        <div className="d-flex section-width-users-3 justify-content-start align-items-center">
          <p className="text-light ms-1 word-break">{user.email}</p>
        </div>
        <div className="section-width-users-4 d-flex align-items-center">
          <p className="text-light word-break">
            {user.phone ? user.phone : "-"}
          </p>
        </div>
        <div className="section-width-users-4 d-flex align-items-center">
          {user?.active ? (
            <button onClick={() => { handleStatus(user?.id, user?.active) }} className="btn btn-success text-white small">
              Active
            </button>
          ) : (
            <button onClick={() => { handleStatus(user?.id, user?.active) }} className="btn btn-danger text-white small">
              Deactive
            </button>
          )}
        </div>
        <div className="section-width-users-5 staff-icon d-flex justify-content-end align-items-center">
          <div className="margin-icon">
            <IconButton
              icon={cilPencil}
              size="lg"
              onClick={handleVisibleEdit}
            />
          </div>
          <div>
            {previousAction ? (
              <IconButton
                icon={cilTrash}
                size="lg"
                className="ms-1"
                isLoading={isLoading}
                onClick={() => {
                  toast.error("Please confirm the previous action")
                }}
              />
            ) : (
              <IconButton
                icon={cilTrash}
                size="lg"
                className="ms-1"
                isLoading={isLoading}
                onClick={() => {
                  onPressRemoveUser(organizationId, user.id)
                }}
              />
            )}
          </div>
        </div>
      </div>
      <EditAdmin
        visible={visibleEdit}
        refreshAdmins={refreshAdmins}
        closeModal={closeModal}
        user={user}
        organizationId={organizationId}
      />
    </>
  );
};

export default AdminsRow;

