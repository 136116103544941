import * as React from "react";
import "./Admins.scss"
import { User } from "../../services/types";
import { OrgSelected } from "../../utils/types";
//services
import { getAdmins } from "../../services/AdminServices";
//components
import { cilPeople, cilPlus } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { Input, MenuHeader, Pagination } from "../atomics";
import AdminsRow from "./AdminsRow";
import HandleUIError from "../../utils/HandleUIError";
import { CSpinner } from '@coreui/react';
import AddAdmin from './AddAdmin';

interface Props {
    organization: OrgSelected;
}

const AdminsList: React.FC<Props> = ({ organization }) => {
    const [users, setUsers] = React.useState<Array<User>>([])
    const [loading, setLoading] = React.useState<boolean>(false);
    const [visibleNewUser, setVisibleNewUser] = React.useState<boolean>(false);
    const [isPreviousAction, setIsPreviousAction] = React.useState<boolean>(false);
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [pageSize] = React.useState<number>(10);
    const [pageNumber, setPageNumber] = React.useState<number>(1);
    const [order, setOrder] = React.useState<any>('DESC');
    const [searchedData, setSearchedData] = React.useState<any>("");
    const [results, setResults] = React.useState([])

    function openNewUserModal() {
        setVisibleNewUser(true)
    }

    function closeNewUserModal() {
        setVisibleNewUser(false)
    }

    async function refreshAdmins() {
        setLoading(true)
        try {
            const response = await getAdmins(
                organization.id,
                pageSize,
                currentPage,
                order,
                searchedData,
            );
            setUsers(response.data);
            setPageNumber(response.total / pageSize);
            setLoading(false)
        } catch (e) {
            HandleUIError(e)
            setLoading(false)
        }
    }

    React.useEffect(
        function () {
            refreshAdmins();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [currentPage])



    const handleChangeOrder = (e: any) => {
        setOrder(e.target.value)
    }

    const orderSorting = async (order: any) => {
        try {
            const res = await getAdmins(
                organization.id,
                pageSize,
                currentPage,
                order,
                searchedData,
            );
            // setLoading(false)
            console.log("data", res?.data);
            setUsers(res.data);
        } catch (e) {
            setLoading(false);
            HandleUIError(e);
        }
    }

    React.useEffect(() => {
        orderSorting(order)
    }, [order])

    const handleInputChange = (event: any) => {
        setSearchedData(event.target.value);
    };

    const search = async (searchedData: any) => {

        try {
            const res = await getAdmins(
                organization.id,
                pageSize,
                currentPage,
                order,
                searchedData,
            );
            // setLoading(false)
            console.log("data", res?.data);
            setUsers(res.data);
        } catch (e) {
            setLoading(false);
            HandleUIError(e);
        }

    }

    React.useEffect(() => {
        search(searchedData)

        // Fetch data only if query is not empty
        if (searchedData.trim()?.length > 2) {
            search(searchedData);
        } else {
            // Clear results if query is empty
            setResults([]);
        }
    }, [searchedData]
    );

    return (
        <>
            <div className="d-flex align-items-center">
                <p className="text-color fs-3 me-3">Admins</p>
                <div className="col-lg-6 ms-auto">
                    <div className="row justify-content-end">
                        <div className="col-lg-5">

                            <select
                                onChange={handleChangeOrder}
                                value={order}
                                className="form-select input-select-custom"
                                id="exampleFormControlSelect1"
                            >
                                <option value={"DESC"} selected={order === "DESC"}>Descending Order</option>
                                <option value={"ASC"} selected={order === "ASC"}>Ascending Order</option>
                            </select>
                        </div>
                        <div className="col-lg-5">
                            <Input
                                type="search"
                                className="w-100 small"
                                placeholder="Search By Name"
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </div>
                <div
                    className="add-icon-container mg-lt add-icon-staff"
                    onClick={openNewUserModal}
                >
                    <CIcon icon={cilPlus} size="xl" className="add-icon" />
                </div>
            </div>
            <div>
                <p className="text-light fs-5 me-3">{organization.name}</p>
                <p className="text-light fs-5 me-3">{organization.address}</p>
            </div>
            <div className="users-container pb-4">
                <MenuHeader className="d-flex pt-3 pb-3 mt-1">
                    <CIcon
                        icon={cilPeople}
                        size="xl"
                        className="text-color section-width-1 ms-1 d-none d-sm-block justify-content-center people-icon"
                    />
                    <p className="text-color section-width-users-2 d-none d-sm-block">User</p>
                    <p className="text-color section-width-users-3 d-none d-sm-block">Email</p>
                    <p className="text-color section-width-users-4 d-none d-sm-block">Phone</p>
                    <p className="text-color section-width-users-4 d-none d-sm-block">Status</p>
                    <span className="text-color section-width-users-5 d-none d-sm-block"></span>
                </MenuHeader>
                {!loading ? (
                    <>
                        {users.length > 0 ? (
                            <>
                                {users.map((user, index) => {
                                    return (
                                        <AdminsRow
                                            user={user}
                                            key={index}
                                            refreshAdmins={refreshAdmins}
                                            organizationId={organization.id}
                                            setPreviousAction={setIsPreviousAction}
                                            previousAction={isPreviousAction}
                                        />
                                    )
                                })}
                            </>
                        ) : (
                            <div className="d-flex justify-content-center mt-3 text-light fs-5">No admins to show</div>
                        )}
                    </>
                ) : (
                    <div className="d-flex justify-content-center mt-3"><CSpinner variant="grow" /></div>
                )}
            </div>
            <>
                {pageNumber > 1 ? (
                    <Pagination
                        numberOfPages={pageNumber}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                    />
                ) : (
                    <></>
                )}
            </>
            <AddAdmin
                visible={visibleNewUser}
                closeModal={closeNewUserModal}
                refreshAdmins={refreshAdmins}
                organizationId={organization.id}

            />
        </>
    );
};

export default AdminsList;