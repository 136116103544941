import React from 'react'

import { Line } from "react-chartjs-2";

// import { CategoryScale, Chart } from "chart.js";

// Chart.register(CategoryScale);


import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    scales: {
        xAxes: [{
            barThickness: 73
        }]
    },
    plugins: {

        legend: {
            position: "top",
            labels: {
                // color: "rgb(255 255 255 / 75%)",
                color: "black",
                font: {
                    size: 30
                }
            },
        },
    },
    scales: {
        y: {
            ticks: {
                color: "black",

                font: {
                    size: 10,
                },
                stepSize: 1,
                beginAtZero: true,
            },

        },
        x: {
            ticks: {
                color: "black",

                font: {
                    size: 14,
                },
                stepSize: 1,
                beginAtZero: true,
            },
            xAxes: [{
                categorySpacing: 0
            }],
        },
    },
};

const labels = ["Jan", "Feb ", "Mar", "Apr", "May", "Jun"];

const barData = {
    labels,
    datasets: [
        {
            label: "Total Vistors",
            data: [65, 59, 80, 81, 65, 59, 80, 81],
            backgroundColor: "#0D6EFD",
            borderRadius: 5,
            width: "5px",
            height: 350
        },

    ],
};


// const data = {
//     labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
//     datasets: [
//         {
//             label: "First dataset",
//             data: [33, 53, 85, 41, 44, 65],
//             fill: false,
//             backgroundColor: "rgba(75,192,192,0.2)",
//             borderColor: "rgba(75,192,192,1)"
//         },
//         {
//             label: "Second dataset",
//             data: [33, 25, 35, 51, 54, 76],
//             fill: false,
//             borderColor: "#742774"
//         }
//     ]
// };


const Charts = () => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <div>Charts</div>

                    </div>

                    <div className="col-md-6">
                        {/* <Line data={data} /> */}
                        <Bar options={options} data={barData} />

                    </div>

                </div>
            </div>
        </>
    )
}

export default Charts
