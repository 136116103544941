import React, { useEffect, useState } from "react";
import { ButtonForGroup, GroupButtons } from "../atomics/GroupButtons";
import Select from 'react-select';


import {
  PageContainer,
  Button,
  MenuHeader,
  Divider,
  Pagination,
  IconButton,
  Input,
  Spinner,
  Modal,
} from "../../components/atomics";
import CIcon from "@coreui/icons-react";
import {
  cilEnvelopeClosed,
  cilMediaPlay,
  cilMediaStop,
  cilPencil,
  cilText,
  cilTrash,
  cilVolumeHigh,
  cilX,
} from "@coreui/icons";
import { TextEditor } from "../PredefinedMessages";
import s3 from "../../config/aws-config";
import {
  createTourCategory,
  createTourSection,
  deleteTourCategory,
  deleteTourSection,
  getTourCategories,
  getTourSection,
  updateTourCategory,
  updateTourSection,
} from "../../services/TourCategoryService";
import HandleUIError from "../../utils/HandleUIError";
import confirmationDialog from "../toats-dialogs/ConfirmationDialog";
import AppTourSectionList from "./AppTourSectionList";


const customStyles = {
  control: (provided) => ({
    ...provided,
    background: '#38373D',
    display: 'flex',
    flexWrap: 'nowrap',
    borderColor: '#505050',
    width: '100%',
  }),
  input: (provided) => ({
    ...provided,
    color: 'white', // Change the text color here
  }),
  menu: (provided) => ({
    ...provided,
    background: '#24242C',
    width: '100%'
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isFocused ? '#555' : provided.background,
    '&:hover': {
      background: '#555' // Change the background color on hover
    }
  })
};

const AppTourSection = () => {
  const [isEdit, setIsEdit] = useState(null);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [category, setCategory] = useState([]);
  const [name, setName] = useState("");
  const [stepNo, setStepNo] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [video, setVideo] = useState(null);
  const [des, setDes] = useState("");

  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);

  const [title, setTitle] = useState("")
  const [limit, setLimit] = useState(0)
  const [offSet, setOffSet] = useState(0)
  const [nextDisabled, setNextDisabled] = useState(false)

  const [searchedData, setSearchedData] = useState("")
  const [results, setResults] = useState("")
  const [order, setOrder] = useState("DESC")
  const [editData, setEditData] = useState({})

  const MAX_SIZE_ORGANIZATIONS = 10;
  const maxWidth = 200;
  const maxHeight = 200;
  const maxSizeInBytes = 1024 * 4048; // 2MB

  const [numberOfPages, setNumberOfPages] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [editDataRow, setEditDataRow] = useState(null);
  const [errorImage, setErrorImage] = useState(false);


  const handleFileChange = (event) => {
    const files = event.target.files;

    if (files) {
      // Filter images with dimensions less than 200x200 and size less than 1MB
      const filteredImages = Array.from(files).filter((file) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);

        return (
          img.width <= maxWidth &&
          img.height <= maxHeight &&
          file.size <= maxSizeInBytes
        );
      });

      if (filteredImages.length < 1) {
        setErrorImage(true);
      } else {
        setErrorImage(false);
      }
      setVideo(filteredImages[0]);
    }
  };


  const getCategoriesData = async () => {
    try {
      const res = await getTourCategories();
      // setLoading(false)
      // console.log("getCategoriesData=>", res?.data?.rows);
      setCategory(res?.data?.rows);
    } catch (e) {
      HandleUIError(e);
    }
  };


  const handleNext = async () => {
    setLoading(true);
    setOffSet(offSet + 10)
    try {
      const res = await getTourSection(offSet + 10);
      // setLoading(false)
      console.log("data", res.data?.rows);
      if (res?.data.length < 11) {
        setNextDisabled(true);
      }

      setData(res.data);
    } catch (e) {
      setLoading(false);
      HandleUIError(e);
    }
  }

  const handlePrev = async () => {
    setLoading(true);
    setOffSet(offSet - 10)
    try {
      const res = await getTourSection(offSet - 10);
      setNextDisabled(false);
      // setLoading(false)
      console.log("data", res.data?.rows)
      setData(res.data);
    } catch (e) {
      setLoading(false);
      HandleUIError(e);
    }
  }

  const handleInputChange = (event) => {
    setSearchedData(event.target.value);
  };

  const getSectionData = async (page) => {
    try {
      const res = await getTourSection(page);
      // setLoading(false)
      console.log("getSectionData", res?.data?.rows);
      setNumberOfPages(res?.data?.count / MAX_SIZE_ORGANIZATIONS);

      // console.log(first)
      setData(res?.data?.rows);
    } catch (e) {
      HandleUIError(e);
    }
  };

  const handleFileUpload = async (file) => {
    return new Promise((resolve, reject) => {
      const params = {
        Bucket: "callguardian",
        Key: file.name,
        Body: file,
        ContentType: file.type,
        ContentDisposition: "inline",
      };

      s3.upload(params, (err, data) => {
        if (err) {
          console.error("Error uploading file:", err);
          reject(err);
        } else {
          console.log("File uploaded successfully:", data.Location);
          resolve(data.Location);
        }
      });
    });
  };

  const handleAdd = () => {
    setIsEdit(null);
    setName("");
    setDes("");
    setStepNo("");
    setThumbnail(null);
    setVideo(null);
    setEditDataRow(null)
    setVisible(true);
  };
  const handleEdit = (data) => {
    setEditDataRow(data)
    setIsEdit(data);
    setName(data?.category_id);
    setDes(data?.description);
    setStepNo(data?.step_no);
    setThumbnail("")
    setVideo("")
    setVisible(true);
  };
  const handleClose = () => {
    setVisible(null);
    setVisible(false);
  };


  const newName = category?.find((item) => item?.id == name)
  console.log('newName', name)

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!errorImage) {
      setLoading(true)
      const thumbnailImage = thumbnail ? await handleFileUpload(thumbnail) : editDataRow?.thumbnail_image;
      const VideoFile = video ? await handleFileUpload(video) : editDataRow?.video_file;

      const data = {
        category_id: name?.value,
        step_no: stepNo,
        thumbnail_image: thumbnailImage,
        video_file: VideoFile,
        description: des,
      };

      if (isEdit) {
        try {
          const res = await updateTourSection(data, isEdit?.id);
          // setLoading(false)
          if (res?.status === 200) {
            await getSectionData();
            handleClose();
            setLoading(false)
            setErrorImage(false)
          }
        } catch (e) {
          setLoading(false);
          HandleUIError(e);
          setLoading(false)
        }
      } else {
        try {
          const res = await createTourSection(data);
          // setLoading(false)
          if (res?.status === 200) {
            await getSectionData();
            handleClose();
            setLoading(false)
            setErrorImage(false)
          }
        } catch (e) {
          setLoading(false);
          HandleUIError(e);
          setLoading(false)
        }
      }
    }
  };

  useEffect(() => {
    getSectionData(currentPage)
  }, [currentPage])


  useEffect(() => {
    getSectionData(currentPage);
    getCategoriesData();
  }, []);


  const search = async (searchedData) => {
    try {
      const res = await getTourSection(0, searchedData);
      // setLoading(false)
      console.log("data", res?.data);
      setData(res?.data?.rows);
    } catch (e) {
      setLoading(false);
      HandleUIError(e);
    }
  }

  useEffect(async () => {
    search(searchedData)

    // Fetch data only if query is not empty
    if (searchedData.trim()?.length > 2) {
      search(searchedData);
    } else {
      // Clear results if query is empty
      setResults([]);
    }
  }, [searchedData]);

  useEffect(() => {
    orderSorting(order)
  }, [order])

  const handleChangeOrder = (e) => {
    setOrder(e.target.value)
  }

  const orderSorting = async (order) => {
    try {
      const res = await getTourSection(0, searchedData, order);
      // setLoading(false)
      console.log("data", res?.data);
      setData(res.data?.rows);
    } catch (e) {
      setLoading(false);
      HandleUIError(e);
    }
  }


  return (
    <>
      <div className="bg-main h-100 overflow-auto pb-3">
        <div className="">
          <PageContainer className="pre-stored-messages-container d-flex flex-column p-0">
            <div className="d-flex px-4 justify-content-between align-items-center messages-header-container">
              <p className="text-light fs-5">App Tour Section</p>
              <Button className="buttons-messages-screen" onClick={handleAdd}>
                New
              </Button>
            </div>

            <div className="row mt-4 justify-content-end">

              <div className="col-lg-3 ">
                <div class="form-group">
                  {/* <label for="exampleFormControlSelect1">Example select</label> */}
                  <select
                    onChange={handleChangeOrder}
                    value={order}
                    className="form-select input-select-custom"
                    id="exampleFormControlSelect1"
                  >
                    <option value={"DESC"} selected={order === "DESC"}>Descending Order</option>
                    <option value={"ASC"} selected={order === "ASC"}>Ascending Order</option>
                  </select>
                </div>
              </div>
              <div className="me-3  col-lg-3">

                <Input
                  type="search"
                  className="w-100 small pe-2"
                  placeholder="Search By Name"
                  onChange={handleInputChange}
                />

                {/* <input
                  value={searchedData}
                  name={searchedData}
                  onChange={handleInputChange}
                  type="text"
                  className="form-control bg-transparent text-white "
                  placeholder="Search" /> */}
              </div>
            </div>

            <div className="mb-4">
              <MenuHeader className="d-flex py-3 mx-3 mt-4">
                {/* <p className="text-color ps-4 w-25">Id</p> */}

                <p className="text-color ps-2 w-25">Name</p>

                <p className="text-color ps-3 w-25">Category</p>

                <p className="text-color ps-3 w-25">Image</p>
                <p className="text-color ps-3 w-25">Video</p>
                <p className="text-color pe-4 w-25 text-end" style={{ visibility: "hidden" }}>Action</p>
              </MenuHeader>
              {Array.isArray(data) && data?.length > 0
                ? data?.map((item) => (
                  <AppTourSectionList
                    item={item}
                    getSectionData={() => getSectionData(currentPage)}
                    handleEdit={handleEdit}
                  />
                ))
                : <h4 className="text-center text-light my-2 fw-light">No Data Found</h4>}
            </div>

            <div className="py-3 ms-3">
              {numberOfPages > 1 ? (
                <Pagination
                  numberOfPages={numberOfPages}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              ) : (
                <></>
              )}
            </div>

          </PageContainer>
        </div>
      </div>

      {visible && (
        <>
          <Modal visible={visible} onPressOut={() => setVisible(false)}>
            <form onSubmit={handleSubmit} className="new-template-container">
              <div className="new-template-header d-flex justify-content-between align-items-center">
                <p className="text-light fs-5">{isEdit ? 'Edit' : 'New'} App Tour</p>
                <IconButton icon={cilX} onClick={() => setVisible(false)} />
              </div>
              <>
                <div
                  className="search-phone pb-2"
                  style={{ maxHeight: "80dvh", overflowY: "auto" }}
                >
                  <div className="px-4 py-2">
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                      <p className="text-light fs-6 m-0 mb-1 w-100">
                        Category
                      </p>
                      {/* <select
                        className="form-control input-custom"
                        onChange={(e) => setName(e.target.value)}
                        required={true}
                        value={name}
                      >
                        <option value="">Please Select</option>
                        {category?.map((item) => (
                          <option value={`${item?.id}`}>{item?.name}</option>
                        ))}
                      </select> */}



                      <Select
                        styles={customStyles}
                        onChange={(e) => setName(e)}
                        className="basic-single w-100"
                        classNamePrefix="select"
                        defaultValue={{ label: newName?.name, value: newName?.id }}
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        options={category.map((item) => { return { label: item?.name, value: item?.id } })}
                      />



                    </div>
                  </div>
                  <div className="px-4 py-2">
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                      <p className="text-light fs-6 m-0 mb-1 w-100">Name</p>
                      <Input
                        className="text-light   w-100"
                        // onChange={onChangeTitle}
                        value={stepNo}
                        onChange={(e) => setStepNo(e.target.value)}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="px-4 py-2">
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                      <p className="text-light fs-6 m-0 mb-1 w-100">
                        Thumbnail Image
                      </p>
                      <input
                        className="text-light form-control w-100"
                        type="file"
                        onChange={(e) => setThumbnail(e.target.files[0])}
                        // onChange={onChangeTitle}
                        required={!isEdit}
                        style={{ backgroundColor: "#38373D" }}
                        accept="image/*"
                      />
                      {isEdit?.thumbnail_image && (
                        <img src={isEdit?.thumbnail_image} alt="thumbnail" width={80} height={80} className="p-1" />
                      )}
                    </div>
                  </div>
                  <div className="px-4 py-2">
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                      <p className="text-light fs-6 m-0 mb-1 w-100">
                        Video File
                      </p>
                      <input
                        id="movie"
                        name="movie"
                        className="text-light form-control w-100"
                        type="file"
                        onChange={(e) => setVideo(e.target.files[0])}
                        // onChange={handleFileChange}
                        required={!isEdit}
                        accept="video/*,image/gif"
                        style={{ backgroundColor: "#38373D" }}
                      // onChange={onChangeTitle}
                      />
                      {errorImage ?
                        <>
                          <small className="text-danger">File size too large.</small>
                        </> :
                        <></>
                      }

                      {isEdit?.video_file && (
                        <video src={isEdit?.video_file} width={80} height={100} className="p-1" />
                      )}
                    </div>
                  </div>
                  <div className="px-4 py-2">
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                      <p className="text-light fs-6 m-0 mb-1 w-100">
                        Description
                      </p>
                      <textarea
                        className="input-custom form-control"
                        rows={20}
                        value={des}
                        onChange={(e) => setDes(e.target.value)}
                        style={{ height: 200 }}
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-end me-3 mb-3">
                  <Button
                    // onClick={createTemplate}
                    // disabled={loading}
                    type="submit"
                    className="px-4"
                  >
                    {loading ? <Spinner className="small" /> : 'Save'}
                  </Button>
                </div>
              </>
            </form>
          </Modal>
        </>
      )}
    </>
  );
};

export default AppTourSection;
