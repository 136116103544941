import axiosConfig from "./axiosConfig";
import ApiError from "../utils/ApiError";


export async function getProfile() {
    try {
        const response = await axiosConfig.get(`me`);
        return response?.data
    } catch (e) {
        throw new ApiError(e);
    }
}

export async function updateProfile(data) {
    try {
        const response = await axiosConfig.post(`admin/update-profile`, data);
        return response?.data
    } catch (e) {
        throw new ApiError(e);
    }
}
export async function updatePassword(data) {
    try {
        const response = await axiosConfig.post(`admin/change-password`, data);
        return response?.data
    } catch (e) {
        throw new ApiError(e);
    }
}

