import React, { useEffect, useState } from "react";
import { ButtonForGroup, GroupButtons } from "../atomics/GroupButtons";
import {
  PageContainer,
  Button,
  MenuHeader,
  Divider,
  Pagination,
  IconButton,
  Input,
  Spinner,
  Modal,
} from "../../components/atomics";
import CIcon from "@coreui/icons-react";
import {
  cilEnvelopeClosed,
  cilMediaPlay,
  cilMediaStop,
  cilPencil,
  cilText,
  cilTrash,
  cilVolumeHigh,
  cilX,
} from "@coreui/icons";
import { AudioRecorder, TextEditor } from "../PredefinedMessages";
import HandleUIError from "../../utils/HandleUIError";
import {
  createTourCategory,
  deleteTourCategory,
  getTourCategories,
  updateTourCategory,
} from "../../services/TourCategoryService";
import confirmationDialog from "../toats-dialogs/ConfirmationDialog";
import AppTourCategoryList from "./AppTourCategoryList";
import toast from "react-hot-toast";

const AppTourCategory = () => {
  const [isEdit, setIsEdit] = useState(null);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [data, setData] = useState([]);


  const [title, setTitle] = useState("")
  const [limit, setLimit] = useState(0)
  const [offSet, setOffSet] = useState(0)
  const [nextDisabled, setNextDisabled] = useState(false)

  const [searchedData, setSearchedData] = useState("")
  const [results, setResults] = useState("")
  const [order, setOrder] = useState("DESC")

  const MAX_SIZE_ORGANIZATIONS = 10;
  const [numberOfPages, setNumberOfPages] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);


  const getCategoriesData = async () => {
    setLoading(true);
    try {
      const res = await getTourCategories(currentPage);
      // setLoading(false)
      console.log("data", res?.data?.rows);
      setNumberOfPages(res?.data?.count / MAX_SIZE_ORGANIZATIONS);
      if (res?.meta?.total < 10) {
        setNextDisabled(true)
      }
      setData(res.data?.rows);
    } catch (e) {
      setLoading(false);
      HandleUIError(e);
    }
  };

  const handleNext = async () => {
    setLoading(true);
    setOffSet(offSet + 10)
    try {
      const res = await getTourCategories(offSet + 10);
      // setLoading(false)
      console.log("data", res?.data);
      if (res?.data.length < 10) {
        setNextDisabled(true);
      }

      setData(res.data?.rows);
    } catch (e) {
      setLoading(false);
      HandleUIError(e);
    }
  }

  const handlePrev = async () => {
    setLoading(true);
    setOffSet(offSet - 10)
    try {
      const res = await getTourCategories(offSet - 10);
      setNextDisabled(false);
      // setLoading(false)
      console.log("data", res?.data)
      setData(res.data?.rows);
    } catch (e) {
      setLoading(false);
      HandleUIError(e);
    }
  }

  const handleAdd = () => {
    setIsEdit(null);
    setName("");
    setVisible(true);
  };
  const handleEdit = (data) => {
    setIsEdit(data);
    setName(data?.name);
    setVisible(true);
  };
  const handleClose = () => {
    setVisible(null);
    setVisible(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEdit) {
      try {
        const res = await updateTourCategory({ name }, isEdit?.id);
        // setLoading(false)
        if (res?.status === 200) {
          await getCategoriesData();
          handleClose();
          toast.success("Category updated succesfully");
        }
      } catch (e) {
        setLoading(false);
        HandleUIError(e);
      }
    } else {
      try {
        const res = await createTourCategory({ name });
        // setLoading(false)
        if (res?.status === 200) {
          await getCategoriesData();

          handleClose();
          toast.success("Category updated succesfully");
        }
      } catch (e) {
        setLoading(false);
        HandleUIError(e);
      }
    }
  };

  const handleInputChange = (event) => {
    setSearchedData(event.target.value);
  };

  const search = async (searchedData) => {
    try {
      const res = await getTourCategories(currentPage, searchedData);
      // setLoading(false)
      console.log("data", res?.data);
      setData(res.data?.rows);
    } catch (e) {
      setLoading(false);
      HandleUIError(e);
    }
  }

  useEffect(async () => {
    search(searchedData)

    // Fetch data only if query is not empty
    if (searchedData.trim()?.length >= 2) {
      search(searchedData);
    } else {
      // Clear results if query is empty
      setResults([]);
    }
  }, [searchedData]);


  useEffect(() => {
    getCategoriesData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    orderSorting(order)
  }, [order])

  const handleChangeOrder = (e) => {
    setOrder(e.target.value)
  }

  const orderSorting = async (order) => {
    try {
      const res = await getTourCategories(0, searchedData, order);
      // setLoading(false)
      console.log("data", res?.data?.rows);
      setData(res.data?.rows);
    } catch (e) {
      setLoading(false);
      HandleUIError(e);
    }
  }





  return (
    <>
      <div className="bg-main h-100 overflow-auto pb-3">
        <div className="">
          <PageContainer className="pre-stored-messages-container d-flex flex-column p-0">
            <div className="d-flex px-4 justify-content-between align-items-center messages-header-container">
              <p className="text-light fs-5">App Tour Category</p>
              <Button className="buttons-messages-screen" onClick={handleAdd}>
                New
              </Button>
            </div>

            <div className="row mt-4 justify-content-end">

              <div className="col-lg-3">
                <div class="form-group">
                  {/* <label for="exampleFormControlSelect1">Example select</label> */}
                  <select
                    onChange={handleChangeOrder}
                    value={order}
                    className="form-select input-select-custom"
                    id="exampleFormControlSelect1"
                  >
                    <option value={"DESC"} selected={order === "DESC"}>Descending Order</option>
                    <option value={"ASC"} selected={order === "ASC"}>Ascending Order</option>
                  </select>
                </div>
              </div>
              <div className="me-3  col-lg-3">
                <Input
                  type="search"
                  className="w-100 small pe-2"
                  placeholder="Search By Name"
                  onChange={handleInputChange}
                />
                {/* <input
                  value={searchedData}
                  name={searchedData}
                  onChange={handleInputChange}
                  type="text"
                  className="form-control text-white bg-transparent "
                  placeholder="Search" /> */}
              </div>
            </div>


            <div className="pb-4">
              <MenuHeader className="d-flex py-3 mx-3 mt-4">
                <p className="text-color ps-2 w-25">S.No</p>
                <p className="text-color ps-2 w-50">Category</p>
                <div className="d-flex align-items-center justify-content-end pe-2 w-50">
                  &nbsp;
                </div>
              </MenuHeader>
              {data?.length > 0
                ? data?.map((category, index) => (
                  <AppTourCategoryList
                    category={category}
                    index={index}
                    getCategoriesData={getCategoriesData}
                    handleEdit={handleEdit}
                  />
                ))
                : <h4 className="text-center text-light my-2 fw-light">No Data Found</h4>}
            </div>

            <div className="py-3 ms-3">
              {numberOfPages > 1 ? (
                <Pagination
                  numberOfPages={numberOfPages}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              ) : (
                <></>
              )}
            </div>
          </PageContainer>
        </div>
      </div>

      {visible && (
        <>
          <Modal visible={visible} onPressOut={handleClose}>
            <form onSubmit={handleSubmit} className="new-template-container">
              <div className="new-template-header d-flex justify-content-between align-items-center">
                <p className="text-light fs-5">New Category</p>
                <IconButton icon={cilX} onClick={() => setVisible(false)} />
              </div>
              <>
                <div className="search-phone pb-2">
                  <div className="px-3 py-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="text-light fs-5 m-0 me-2">Name</p>
                      <Input
                        className="text-light  w-100"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required={true}
                      // onChange={onChangeTitle}
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-end me-3 mb-3">
                  <Button
                    // disabled={loading}
                    type="submit"
                    className="px-4"
                  >
                    Save
                    {/*{loading ? <Spinner /> : "Save"}*/}
                  </Button>
                </div>
              </>
            </form>
          </Modal>
        </>
      )}
    </>
  );
};

export default AppTourCategory;
