import React from "react";
import { IconButton } from "../atomics";
import { cilPencil, cilTrash } from "@coreui/icons";
import { deleteTourSection } from "../../services/TourCategoryService";
import HandleUIError from "../../utils/HandleUIError";
import confirmationDialog from "../toast-dialogs/ConfirmationDialog";
const AppTourSectionList = ({ item, getSectionData, handleEdit }) => {
  const handleDelete = async () => {
    if (item?.id) {
      try {
        const res = await deleteTourSection(item?.id);
        // setLoading(false)
        if (res?.status === 200) {
          await getSectionData();
          // handleClose()
        }
      } catch (e) {
        //         setLoading(false);
        HandleUIError(e);
      }
    }
  };


  function onPressDelete() {
    confirmationDialog({
      title: "Are you sure you want to delete this?",
      confirmAction: handleDelete,
    });
  }
  return (
    <>
      <div
        key={item?.id}
        className="d-flex py-3 mx-3 justify-content-between justify-content-sm-start px-4 user-information-container"
      >
        {/* <div className="d-flex w-25 justify-content-between">
          <div className="message-title d-flex align-items-center w-100 ">
            <p className="ms-2 text-light">{item?.tour_category?.id}</p>
          </div>
        </div> */}


        

        <div className="d-flex w-25 justify-content-between">
          <div className="message-title d-flex align-items-center w-100 ">
            <p className="ms-2 text-light">{item?.step_no}</p>
          </div>
        </div>

        <div className="d-flex w-25 justify-content-between">
          <div className="message-title d-flex align-items-center w-100 ">
            <p className="ms-2 text-light">{item?.tour_category?.name}</p>
          </div>
        </div>

        <div className="d-flex w-25 justify-content-between">
          <div className="message-title d-flex align-items-center w-100 ">
            <img src={item?.thumbnail_image}
              width={64} height={45} alt="" style={{ objectFit: "cover" }} />
            {/* <p className="ms-2 text-light">{item?.tour_category?.name}</p> */}
          </div>
        </div>
        <div className="d-flex w-25 justify-content-between">
          <div className="message-title d-flex align-items-center w-100 ">
            <video
              src={item?.video_file}
              style={{ objectFit: "cover" }}
              // className="h-auto max-h-[600px]  w-full object-contain lg:h-full lg:object-fill  "
              width={64}
              height={45}
              autoPlay
              muted
              loop
            />
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-end pe-2 w-25">
          <IconButton
            icon={cilPencil}
            size="lg"
            className="me-2 edit-template-icon mt-1"
            onClick={() => handleEdit(item)}
          />
          <IconButton
            icon={cilTrash}
            onClick={() => {
              onPressDelete();
            }}
            // isLoading={isLoadingDelete}
            className="m-0"
            size="lg"
          />
        </div>
      </div>
    </>
  );
};

export default AppTourSectionList;
