import axiosConfig from "./axiosConfig";
import { AxiosError } from "axios";
import ApiError from "../utils/ApiError";
import { OrganizationResponse } from "./types";

export async function getOrganizations(
  page: number,
  limit: number,
  order: any,
  status: any,
  title: any

): Promise<{ data: OrganizationResponse[]; total: number }> {
  try {
    const response = await axiosConfig.get(
      `/organizations?page=${page}&limit=${limit}&order=${order}&status=${status}&title=${title}`
    );
    return {
      data: response.data.data.rows,
      total: response.data.data.count,
    };
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

interface NewOrganizationState {
  name: string;
  address: string;
  countryCode: string | number;
  stateCode: string | number;
  postalCode: string | number;
  importTemplates?: boolean,
  custom?: boolean,
  standard?: boolean,
  pre_store_message?: boolean
}

export async function createOrganization(
  newOrganization: NewOrganizationState,
): Promise<void> {
  try {
    await axiosConfig.post("/organizations", {
      ...newOrganization,

    });
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function updateOrganization(
  organizationId: number,
  organizationChange: NewOrganizationState
): Promise<void> {
  try {
    await axiosConfig.patch(
      `/organizations/${organizationId}`,
      organizationChange
    );
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function updateOrganizationStatus(
  organizationId: number,
  status: boolean

): Promise<void> {
  try {
    const body = {
      status: status
    };
    await axiosConfig.put(`/organizations/${organizationId}`, body);
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function deleteOrganization(
  organizationId: number,
): Promise<void> {
  try {
    await axiosConfig.delete(`/organizations/${organizationId}`);
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}