import ApiError from "./ApiError";
import ApiErrorStatusCode from "./ApiErrorStatusCode";
import toast from "react-hot-toast";

function HandleUIError(
  e: unknown,
  interceptor?: (errorStatusCode: number) => void
): void {
  const error = e as ApiError;
  let errorStatusCode: number;
  if (error) {
    errorStatusCode = error.statusCode;
  } else {
    errorStatusCode = 800;
  }

  console.warn(`Error: ${error.message}`, JSON.stringify(error));

  if (interceptor) {
    return interceptor(errorStatusCode);
  }
  switch (errorStatusCode) {
    case ApiErrorStatusCode.ServerError:
      toast.error("Server Error");
      break;
    case ApiErrorStatusCode.BadRequest:
      toast.error("bad request");
      break;
    case ApiErrorStatusCode.NonApiError:
      // toast.error("no api error");
      break;
    default:
      // toast.error("no api error");
      break;
  }
}
export default HandleUIError;
