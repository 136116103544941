import React, { useEffect, useState } from 'react';
import { PageContainer } from '../atomics';
import RenderLineChart from '../Charts/renderLineChart';
import LineCharts from "../Charts/LineCharts";
import { FaCalendarCheck } from "react-icons/fa6";
import { MdFreeCancellation } from "react-icons/md";
import { RiSuitcaseLine } from "react-icons/ri";
import { getDashboardInfo } from '../../services/DashboardService';

const Dashboard = () => {

    const [totalOrganization, setTotalOrganization] = useState("")
    const [activeOrganization, setActiveOrganization] = useState("")
    const [inActiveOrganization, setInActiveOrganization] = useState("")


    const handleDashboard = async () => {
        await getDashboardInfo().then((res) => {
            setTotalOrganization(res?.data?.all_organizations)
            setActiveOrganization(res?.data?.active_organizations)
            setInActiveOrganization(res?.data?.in_active_organizations)
        }).catch((error) => {
            return error
        })

    }
    useEffect(() => {
        handleDashboard()
    }, [])
    return (
        <>
            <div className="bg-main h-100 overflow-auto p-4">
                <div className="row">
                    <div className="col-md-4">
                        <div className="new-staff-container w-100">
                            <div className="new-template-header d-flex justify-content-between align-items-center">
                                <div>
                                    <p className="text-light  px-2 ">Total Organization</p>
                                    <p className="text-light fw-bold px-2 fs-5">{totalOrganization}</p>
                                </div>
                                <RiSuitcaseLine className='text-primary' size={28} />

                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="new-staff-container w-100">
                            <div className="new-template-header d-flex justify-content-between align-items-center">
                                <div>
                                    <p className="text-light  px-2 ">Active Organization</p>
                                    <p className="text-light fw-bold px-2 fs-5">{activeOrganization}</p>
                                </div>
                                <FaCalendarCheck size={25} className='text-success' />

                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="new-staff-container w-100">
                            <div className="new-template-header d-flex justify-content-between align-items-center">
                                <div>
                                    <p className="text-light  px-2 ">Inactive Organization</p>
                                    <p className="text-light fw-bold px-2 fs-5">{inActiveOrganization}</p>
                                </div>
                                <MdFreeCancellation size={28} className='text-danger' />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="new-staff-container w-100">
                            <div className="new-template-header fw-bold d-flex justify-content-center align-items-center">
                                <p className="text-light py-2 px-2 fs-5 text-center">Organizations Status</p>
                            </div>
                            <div className="new-template-header border-0">
                                <RenderLineChart />

                                {/* <LineCharts /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
